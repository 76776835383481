import { AnchorLink } from 'gatsby-plugin-anchor-links'
import * as React from 'react'
import {Hero, Layout, Nav, Typography, Flex, ThreeBackground, Seo, Rodo, Button } from '../components/'

const NotFoundPage = () => (
	<Layout>
		<Seo lang="pl" title={'/ ' + 'polityka prywatności'} />

			<Nav />
	
			<ThreeBackground />
			{/* <Hero /> */}
			<Flex justifyContent="center" alignItems="center" direction="column" height="100vh">

		<Typography type="h3" m="0" p="0.5rem">Zepsułeś internet lub zabłądziłeś?</Typography>
		<Typography type="h3" m="0" p="0.5rem">Strona, której szukasz nie istnieje</Typography>
		<AnchorLink  to="/">
		<Button  accent m="2rem" >Przejdź do strony głównej</Button>
		</AnchorLink>
			</Flex>
		<ThreeBackground />
		
	</Layout>
)

export default NotFoundPage
